import Vue from 'vue'
import Vuex from 'vuex'
import tagsView from './modules/tagsView'
import loading from './modules/loading'
import visitTemplate from './modules/visitTemplate'
import orgSelect from './modules/orgSelect'

Vue.use(Vuex)

// 创建 store 实例
export default new Vuex.Store({
    modules: {
	   tagsView,
	   loading,
	   visitTemplate,
	   orgSelect
	}
})