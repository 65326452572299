import {vueInstance} from '@/main';

async function getDictionary(name) {
    let res = await vueInstance.$http.post("/sys/getDictionary", {type: name});
    if (res.state) {
        if(res.data.length){
            vueInstance.global.DictionaryData[name] = res.data;
            sessionStorage.setItem('DictionaryData', JSON.stringify( vueInstance.global.DictionaryData));
        }
    }
}

export const getoptions = function (name) {      //字典表中通过name获取下拉框
    let DictionaryData = vueInstance.global.DictionaryData;
    if (!DictionaryData[name]) {
        getDictionary(name);
    }
};

export const getname = function (name, val) {       //通过value获取label
    let DictionaryData = vueInstance.global.DictionaryData;
    if (DictionaryData && DictionaryData[name]) {
        for (let item of DictionaryData[name]) {
            if (item.value == val) {
                return item.label
            }
        }
    }
};


export const getcommunityoptions = function () {      //获取社区下拉框内容
    let CommunityDate = JSON.parse(localStorage.getItem("Community"));
    if (CommunityDate) {
        return CommunityDate;
    }
}


export const getcommunityname = function (val) {       //通过value获取label
    let CommunityDate = JSON.parse(localStorage.getItem("Community"));
    if (CommunityDate && CommunityDate.length) {
        for (let item of CommunityDate) {
            if (item.communityId == val) {
                return item.communityName
            }
        }
    }
};