import http from '@/utils/http.js'
import PagiNation from '@/components/PagiNation'
import { Page,Comb} from '@/utils/class.js'
import {getStartDateOfMonth,getEndDateOfMonth,getDate,getDateAddDays,getDateSubtractDays,getDateTime,getBeforeForDate,getAfterForDate,getNowYear,isAfter,getNowMonth,getNowFirstMonth} from '@/utils/moment-ext.js'
import{uploadImg} from '@/utils/uploadImg.js'
import 	echarts from 'echarts'
import {getoptions,getname}  from '@/utils/dictionary.js'

let base = {};

if(!localStorage.getItem('orgId')){
	localStorage.setItem('orgId','[]')
}

base.install = function(Vue, options) {
	Vue.prototype.$http = http;
	Vue.prototype.Page = Page; //公共Page类
	Vue.prototype.Comb = Comb; //公共下拉框处理类
	Vue.component('PagiNation', PagiNation);
	Vue.prototype.uploadImg = uploadImg; //压缩上传图片
	Vue.$echarts=echarts;


	Vue.prototype.getStartDateOfMonth = getStartDateOfMonth;
	Vue.prototype.getEndDateOfMonth = getEndDateOfMonth;
	Vue.prototype.getDate = getDate;
	Vue.prototype.getDateAddDays = getDateAddDays;
	Vue.prototype.getDateTime = getDateTime;
	Vue.prototype.getDateSubtractDays = getDateSubtractDays;
	Vue.prototype.getBeforeForDate = getBeforeForDate;
	Vue.prototype.getAfterForDate = getAfterForDate;
	Vue.prototype.getNowYear = getNowYear;
	Vue.prototype.isAfter = isAfter;
	Vue.prototype.getNowMonth = getNowMonth;
	Vue.prototype.getNowFirstMonth = getNowFirstMonth;
    Vue.prototype.getoptions = getoptions;
    Vue.prototype.getname = getname;

    Vue.prototype.$echarts=echarts;								//echarts图库

    Vue.prototype.IMAGE_BASE = 'http://115.238.110.210:8888/';

    Vue.prototype.isClose = false;
    Vue.prototype.isLoginOut = false;

    //全局变量
    Vue.prototype.global={
        orgId:JSON.parse(localStorage.getItem('orgId')),
        orgTreeProps:{label: 'name', value: 'id', checkStrictly: true},
        orgList:[],
        permitOrgList: [],
        permitorgTreeProps: {label: 'orgName', value: 'orgId', checkStrictly: true},
        searchOrgId: null,
        isAdmin:false,
        DictionaryData:JSON.parse(sessionStorage.getItem('DictionaryData')),
	};

    Vue.prototype.$assessData={
        assessActive:0,
		sheetId:null,
		sheetName:null,
		typeId:null
    };

    Vue.prototype.deepClone = function(item) {
        if (!!item) {
            return JSON.parse(JSON.stringify(item));
        }
    }
};
export {base};