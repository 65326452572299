import axios from 'axios';
import {vueInstance} from '@/main';
import store from '../store/store'
import {trim,trimObj} from './trim'

class RequestCfg {
    constructor(method, url, data) {
        this.method = method;
        this.url = url;
        this.data = data;
    }
}

class HTTP {
    async request(method, url, param,noMessage) {
        let instance = axios.create({
            baseURL: '/api',
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': sessionStorage.getItem('authorization')
            },
        });

        param = trimObj(param);
        let requestCfg = new RequestCfg(method, url, param);
        store.commit('changeLoading', 1);
        try {
            const result = await instance.request(requestCfg);
            if (result.headers.authorization) {
                sessionStorage.setItem('authorization', result.headers.authorization)
            }
            setTimeout(()=>{
                store.commit('changeLoading', -1);
                },500);

            if(result.data.code=="401"){
                vueInstance.$store.dispatch('delAllViews');
                sessionStorage.clear();
                vueInstance.$router.push({path: '/login'});
                return;
            }

            if(result.data.msg && !result.data.state && !noMessage){
                vueInstance.$message.warning(result.data.msg);
            }

            return  result.data;
        } catch (e) {
            console.log('服务器出错');
            vueInstance.$message.error('服务器出错');
            vueInstance.$store.commit('changeLoading', false);
        }
    }

    async post(url, param,noMessage) {
        return this.request('post', url, param,noMessage);
    }

    async get(url, param,noMessage) {
        return this.request('get', url, param,noMessage);
    }

    async downloadFile(url, param){
        let instance = axios.create({
            baseURL: '/api',
            responseType: 'blob',
            timeout: 25000,
            validateStatus: (status) => {
                return status >= 200 && status < 300; //default
            },
            headers: {
                'Authorization': sessionStorage.getItem('authorization')
            },
        });
        let requestCfg = new RequestCfg('post', url, param);
        try {
            const result = await instance.request(requestCfg);
            return  result.data;
        }catch (e) {
            console.log('服务器出错');
            vueInstance.$message.error('服务器出错');
        }
    }
}

let http = new HTTP();

export default http;
