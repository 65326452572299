import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home.vue'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const routes = [
	{path: '/login',name: 'login',component:() => import('@/views/login')},
	{path: '/',name: '系统配置',component:home,meta:{icon:'el-icon-setting'},
		children: [
            {path: '/sysUser',name: 'sysUser',component:() => import('@/views/sysManage/sysUser'),meta:{label:'系统用户'}},
            {path: '/permissionConfig',name: 'permissionConfig',component:() => import('@/views/sysManage/permissionConfig'),meta:{label:'权限配置'}},
            {path: '/roleConfig',name: 'roleConfig',component:() => import('@/views/sysManage/roleConfig'),meta:{label:'角色配置'}},
            {path: '/roleDetail',name: 'roleDetail',component:() => import('@/views/sysManage/roleDetail'),meta:{label:'角色详情',isNotShow:true}},
            {path: '/businessConfig',name: 'businessConfig',component:() => import('@/views/sysManage/businessConfig'),meta:{label:'业务配置'}},
            {path: '/hospitalApi',name: 'hospitalApi',component:() => import('@/views/sysManage/hospitalApi'),meta:{label:'医院接口'}},
            {path: '/timeTask',name: 'timeTask',component:() => import('@/views/sysManage/timeTask'),meta:{label:'定时任务'}},
            {path: '/taskLog',name: 'taskLog',component:() => import('@/views/sysManage/taskLog'),meta:{label:'任务日志'}},
            {path: '/dictionaryManage',name: 'dictionaryManage',component:() => import('@/views/sysManage/dictionaryManage'),meta:{label:'字典管理'}},
            {path: '/tencentConfig',name: 'tencentConfig',component:() => import('@/views/sysManage/tencentConfig'),meta:{label:'公众号配置'}},
            {path: '/backgroundLog',name: 'backgroundLog',component:() => import('@/views/sysManage/backgroundLog'),meta:{label:'后台日志'}},
            // {path: '/orgManage',name: 'orgManage',component:() => import('@/views/sysManage/orgManage'),meta:{label:'机构管理'}},
            {path: '/orgConfig',name: 'orgConfig',component:() => import('@/views/sysManage/orgConfig'),meta:{label:'机构配置'}},
            {path: '/orgBusiness',name: 'orgBusiness',component:() => import('@/views/sysManage/orgBusiness'),meta:{label:'机构业务'}},
            {path: '/orgDep',name: 'orgDep',component:() => import('@/views/sysManage/orgDep'),meta:{label:'机构部门'}},
            {path: '/orgUser',name: 'orgUser',component:() => import('@/views/sysManage/orgUser'),meta:{label:'机构人员'}},
            {path: '/orgTencent',name: 'orgTencent',component:() => import('@/views/sysManage/orgTencent'),meta:{label:'机构公众号'}},
            {path: '/qrcode',name: 'qrcode',component:() => import('@/views/sysManage/qrcode'),meta:{label:'永久二维码'}},
        ]
	},{path: '/',name: '挂号管理',component:home,meta:{icon:'el-icon-first-aid-kit'},
		children: [
            {path: '/registerRecord',name: 'registerRecord',component:() => import('@/views/register/registerRecord'),meta:{label:'挂号记录'}},
            {path: '/hospitalConfig',name: 'hospitalConfig',component:() => import('@/views/register/hospitalConfig'),meta:{label:'医院配置'}},
            {path: '/departmentConfig',name: 'departmentConfig',component:() => import('@/views/register/departmentConfig'),meta:{label:'科室配置'}},
            {path: '/doctorConfig',name: 'doctorConfig',component:() => import('@/views/register/doctorConfig'),meta:{label:'医生配置'}},
            {path: '/registerConfig',name: 'registerConfig',component:() => import('@/views/register/registerConfig'),meta:{label:'挂号配置'}},
            {path: '/orderRecords',name: 'orderRecords',component:() => import('@/views/register/orderRecords'),meta:{label:'动账记录'}},
            {path: '/feedback',name: 'feedback',component:() => import('@/views/register/feedback'),meta:{label:'意见反馈'}},
        ]
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
});


router.onError(error => {
	console.log("+++++++++++++++++++++");
	console.log(error);
	location.reload();
    /*const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.match(pattern);
    console.log(isChunkLoadFailed);
    if(isChunkLoadFailed){
        location.reload();
    }*/
});

export default router
