
class OrgItem{
    constructor(value,label){
        this.label=label;
        this.value=value;
    }
};

const orgSelect = {
    state: {
        currentOrg:null,
        orgList:[],
    },
    mutations: {
        changeOrg:(state,data)=>{										 //机构下拉框的值发生改变
            state.currentOrg = data;
            localStorage.setItem('currentOrg',state.currentOrg);
        },
        updateOrgList:(state,data)=>{
            state.orgList=data;
        },
        initCurrentOrgArr:(state)=>{
            state.currentOrg=null;
        }
    },
    getters:{
        getCurrentOrg(state){
            if(!state.currentOrg){
                state.currentOrg = sessionStorage.getItem('currentOrgArr')||null;
            }
            return state.currentOrg
        }
    },
    actions: {
        getOrgList({commit},userId){
            /*let param ={limit:1000,page: 1,offSet:0,name: null,userId:userId};
            let $http=new Http();
            $http.post('/sys/getOrganizationCombo',param,(res)=>{
                if(res.data.length>0){
                    //设置当前机构ID
                    if(!sessionStorage.getItem('currentOrgArr')){
                        commit('changeOrg', [res.data[0].value]);
                    }
                    commit('updateOrgList', res.data);
                }
            },err=>{
                this.$message.error("获取机构信息错误，请重新登录！");
            });*/
        }
    }
}

export default orgSelect
