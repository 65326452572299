
class Visit{
    constructor(){
        this.id=0;
        this.type=1;
        this.name=null;
        this.content=null;
        this.disEdit=false;
    }
    reset(){
        this.constructor();
    }
}

const visitTemplate = {
    state: {
        telphoneVisit:new Visit(),
    },
    mutations: {
        changeTelphoneVisit(state,telphoneVisit) {
            if(telphoneVisit){
                Object.assign(state.telphoneVisit,telphoneVisit);
            }else{
                state.telphoneVisit.reset();
            }
        },
    },
    actions: {
        increment({commit}){
            commit('INCREMENT')
        },
        decrement({commit}){
            commit('DECREMENT')
        }
    }
}
export default visitTemplate
